@font-face {
  font-family: 'Calibri';
  src: local('Calibri Light'), local('Calibri-Light'),
    url('assets/fonts/Calibri-Light.woff2') format('woff2'),
    url('assets/fonts/Calibri-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri';
  src: local('Calibri Light Italic'), local('Calibri-LightItalic'),
    url('assets/fonts/Calibri-LightItalic.woff2') format('woff2'),
    url('assets/fonts/Calibri-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri';
  src: local('Calibri'), url('assets/fonts/Calibri.woff2') format('woff2'),
    url('assets/fonts/Calibri.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri';
  src: local('Calibri Italic'), local('Calibri-Italic'),
    url('assets/fonts/Calibri-Italic.woff2') format('woff2'),
    url('assets/fonts/Calibri-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri';
  src: local('Calibri Bold'), local('Calibri-Bold'),
    url('assets/fonts/Calibri-Bold.woff2') format('woff2'),
    url('assets/fonts/Calibri-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri';
  src: local('Calibri Bold Italic'), local('Calibri-BoldItalic'),
    url('assets/fonts/Calibri-BoldItalic.woff2') format('woff2'),
    url('assets/fonts/Calibri-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Carlito';
  font-style: normal;
  font-weight: normal;
  src: local('Carlito'), url('assets/fonts/Carlito-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Carlito';
  font-style: italic;
  font-weight: normal;
  src: local('Carlito Italic'), local('Carlito-Italic'),
    url('assets/fonts/Carlito-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Carlito';
  font-style: normal;
  font-weight: bold;
  src: local('Calibri Bold'), local('Calibri-Bold'),
    url('assets/fonts/Carlito-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Carlito';
  font-style: italic;
  font-weight: bold;
  src: local('Calibri Bold Italic'), local('Calibri-BoldItalic'),
    url('assets/fonts/Carlito-BoldItalic.woff') format('woff');
}
